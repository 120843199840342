import type { FC } from 'react';
import React, { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { SnackbarProvider } from 'notistack';
import { StyledEngineProvider, ThemeProvider, useMediaQuery } from '@mui/material';
import { QueryClient, QueryClientProvider } from 'react-query';
import GlobalStyles from 'components/GlobalStyles';
import SplashScreen from 'pages/authentication/SplashScreen';
import useAuth from './hooks/useAuth';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import routes from './routes';
import { createTheme } from './theme';
import ProfileValidator from './pages/authentication/profile/ProfileValidator';
import SettingsDrawer from './components/SettingsDrawer';
import { KeycloakProvider } from './contexts/KeycloakContext';


const queryClient = new QueryClient();
const App: FC<React.PropsWithChildren<unknown>> = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();

  const { isInitialized, loadUser, isAuthenticated } = useAuth();
  useScrollReset();

  useEffect(() => {
    if (!isInitialized) {
      loadUser();
    }
  }, []);

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme
  });

  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <SnackbarProvider
                dense
                maxSnack={3}
            >
              <ToastContainer/>
              <GlobalStyles/>
              {isAuthenticated && isLargeScreen && (<SettingsDrawer/>)}
              {
                isInitialized ?
                  (
                        <ProfileValidator>
                          {content}
                        </ProfileValidator>
                  ) :
                  (
                        <SplashScreen/>
                  )
              }
            </SnackbarProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </StyledEngineProvider>
  );
};

export const AppContainer = ()=>{
  return <KeycloakProvider><App /></KeycloakProvider>;
};

export default AppContainer;
