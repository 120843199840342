import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Option } from '../inputs/option';

interface IProps {
  onChange?: (event: React.ChangeEvent<{ value: unknown }>) => any
  value?: any
  label: string
  name: string
  options: Option[]
  margin?: 'none' | 'dense' | 'normal'
  helperText?: string
}

const PRadioInput = (props: IProps) => {
  const { label = '', helperText, value, name, options = [], margin = 'dense', ...rest } = props;

  return (
    <FormControl
      margin={margin}
    >
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <RadioGroup
        {...rest}
        value={value || ''}
        row
      >
        {
            options.map(
              (it) => (
                <FormControlLabel
                  key={it.id}
                  value={it.id}
                  label={it.name}
                  control={<Radio color="primary" />}
                />
              )
            )
          }
      </RadioGroup>
      {
        helperText && <FormHelperText>{helperText}</FormHelperText>
        }
    </FormControl>
  );
};
export default PRadioInput;
