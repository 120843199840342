import { getToken, IClientError } from './ajax';
import { serialize } from './formHelpers';
import { hasValue } from '../components/inputs/inputHelpers';
import { remoteRoutes } from '../constants';
import Toast from './Toast';

const getValidationError = (data: any): string => {
  const error: IClientError = data;
  if (hasValue(error.title) && error.title.indexOf('validation errors') > -1) {
    let { title } = error;
    Object.entries(error.errors).forEach((it) => {
      const [, value] = it;
      title = `${title}\n${value}`;
    });
    return title;
  }
  return null;
};

export const getErrorMessage = async (res: Response): Promise<string> => {
  const defaultMessage = 'Failed to load data, check connectivity.';
  switch (res.status) {
    case 403:
      return 'You don\'t have permission for this page!';
    case 401:
      return 'You need to login to see this page!';
    case 400: {
      const validationError = getValidationError(res);
      if (hasValue(validationError)) {
        return validationError;
      }
      const { message, errors = [] } = await res.json();
      let msg = `${message}: `;

      errors.forEach((it) => {
        const error = Object.values(it)[0];
        msg += (`${error}\n`);
      });
      return msg || defaultMessage;
    }
    default: return defaultMessage;
  }
};

export const handleError = async (res: Response): Promise<any> => {
  const defaultMessage = 'Failed to load data, check connectivity.';

  if (res.status === 403) {
    throw Error('You don\'t have permission for this page!');
  }
  if (res.status === 401) {
    // clearAuthToken();
    // setTimeout(() => {
    //   window.location.reload();
    // }, 1000);
    throw Error('You need to login to see this page!');
  }
  if (res.status === 400) {
    const validationError = getValidationError(res);
    if (hasValue(validationError)) {
      throw Error(validationError);
    }
    const { message, errors = [] } = await res.json();
    let msg = `${message}\n`;

    errors.forEach((it) => {
      const error = Object.values(it)[0];
      msg += (`${error}\n`);
    });
    throw Error(msg || defaultMessage);
  }
  if (res.status === 404) {
    const { message } = await res.json();
    throw Error(message || defaultMessage);
  }
  throw Error("Can't reach server, Check connectivity!");
};

export async function fetchAppUser(id :string, token :string) {
  try {
    const response = await fetch(remoteRoutes.kycProfile, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer'
    });
    if (response.ok) {
      return await response.json();
    }
    return {};
  } catch (error) {
    if (error instanceof Response) {
      Toast.error(await getErrorMessage(error));
    }
    return {};
  }
}

export async function postData(url = '', data = {}) {
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await getToken()}`,
      Accept: 'application/json'
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data)
  });
  if (!response.ok) {
    return handleError(response);
  }
  return response.json();
}

export async function putData(url = '', data = {}) {
  const response = await fetch(url, {
    method: 'PUT',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await getToken()}`,
      Accept: 'application/json'
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data)
  });
  if (!response.ok) {
    return handleError(response);
  }
  return response.json();
}

export async function searchData(url = '', data = {}) {
  const response = await fetch(`${url}?${serialize(data)}`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Authorization: `Bearer ${await getToken()}`,
      Accept: 'application/json'
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer'
  });
  if (!response.ok) {
    return handleError(response);
  }
  return response.json();
}

export async function getData(url) {
  const response = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Authorization: `Bearer ${await getToken()}`,
      Accept: 'application/json'
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer'
  });
  if (!response.ok) {
    return handleError(response);
  }
  return response.json();
}

export async function getBlob(url:string) {
  const response = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Authorization: `Bearer ${await getToken()}`,
      Accept: 'application/json'
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer'
  });
  if (!response.ok) {
    return handleError(response);
  }
  return response.blob().then((blobData) => URL.createObjectURL(blobData));
}

export async function deleteData(url = '', data?:any) {
  const response = await fetch(url, {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Authorization: `Bearer ${await getToken()}`,
      Accept: 'application/json'
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: data ? JSON.stringify(data) : undefined,
  });
  if (!response.ok) {
    return handleError(response);
  }
  return response.json();
}
