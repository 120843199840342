import UserService from './KeycloakAuthService';

import { fetchAppUser } from '../utils/fetchHelpers';
import { uniq } from 'lodash';
import { createContext, useCallback, useState } from 'react';
import { OAuth2ContextType } from './OAuth2Context';
import { AppUser } from '../types/user';


const KeycloakContext = createContext<OAuth2ContextType>({
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  platform: 'keycloak',
  loadUser: () => Promise.resolve(),
  login: () => Promise.resolve(),
  loginRedirectCallback: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  logoutRedirectCallback: () => Promise.resolve(),
  silentRedirectCallback: () => Promise.resolve()
});

export const KeycloakProvider = (props: any) => {
  const oidcUser: AppUser = UserService.isLoggedIn() ? {
    name: UserService.getUsername(),
    id: UserService.getUserId(),
    email: UserService.getUsername(),
    avatar: '',
    ...UserService.getUserData()
  } : null;
  const [user, setUser] = useState<AppUser | null>(oidcUser);
  const [initialized, setInitialized] = useState<boolean>(false);


  const rolesObj = UserService.getRoles();

  const loadUser = useCallback(async (): Promise<AppUser | undefined> => {
    try {
      if (!oidcUser) return undefined;
      const appUser = await fetchAppUser(oidcUser.id, UserService.getToken());
      const userData = {
        ...oidcUser,
        roles: uniq([...rolesObj.realm.roles, ...appUser?.roles || []]),
        partnerId: appUser.partnerId
      };
      console.log('userData', userData);
      setUser(userData);
      return userData;
    } catch (e) {
      return undefined;
    } finally {
      setInitialized(true);
    }
  }, []);

  return <KeycloakContext.Provider  value={{
    user,
    logout: UserService.doLogout,
    loadUser,
    logoutRedirectCallback: UserService.doLogout,
    silentRedirectCallback: () => Promise.resolve(),
    loginRedirectCallback: () => Promise.resolve(),
    isAuthenticated: UserService.isLoggedIn(),
    isInitialized: initialized,
    platform: 'keycloak',
    login: UserService.doLogin,
  }}
  >
    {props.children}
  </KeycloakContext.Provider>;
};

export default KeycloakContext;
