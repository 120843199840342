import type { FC } from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import type { SxProps } from '@mui/system';
import type { Theme } from '@mui/material';
import logo from '../__assets__/verify_logo.png';

interface LogoProps {
  light?: boolean;
  sx?: SxProps<Theme>;
}

const LogoRoot = styled('div')`
  height: 55px;
  width: auto;
`;

const Image = styled('img')`
  height: 100%;
  width: auto;
  alignment: center;
`;

const Logo: FC<React.PropsWithChildren<LogoProps>> = ({ light, ...props }:LogoProps) => (
  <LogoRoot {...props}>
    <Image
      src={logo}
      alt="Logo"
    />
  </LogoRoot>
);

export default Logo;
