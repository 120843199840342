import React from 'react';
import { FormikHelpers } from 'formik/dist/types';
import Grid from '@mui/material/Grid';
import { IColumn } from './types';
import { handleSubmission, ISubmission, sanitizeFormFields, sanitizeFormValues } from '../../utils/formHelpers';
import XForm from '../forms/XForm';
import { renderInput } from './inputRender';
import { del } from '../../utils/ajax';
import Toast from '../../utils/Toast';

interface IProps {
  fields: IColumn[]
  url: string
  data: any | null
  isNew: boolean
  isDeletable: boolean
  debug?: boolean
  done?: () => any
  onPostComplete?: (data: any) => any
  onCancel?: () => any
  onPutComplete?: (data: any) => any
  onDeleteComplete?: (data: any) => any
  primaryKey?: any
  submitParser?: (data: any) => any
  submitResponseParser?: (data: any) => any
}

const EditForm = ({
  data,
  isNew,
  isDeletable,
  url, fields,
  done,
  debug,
  primaryKey = 'id',
  submitParser,
  submitResponseParser,
  onCancel,
  ...props
}: IProps) => {
  const { schemaObject, initialValues, formFields } = sanitizeFormFields(fields);
  function handleSubmit(values: any, actions: FormikHelpers<any>) {
    const cleanValues = sanitizeFormValues(fields, values);

    const toSubmit = submitParser ? submitParser(cleanValues) : cleanValues;

    const submission: ISubmission = {
      url,
      values: toSubmit,
      actions,
      isNew,
      onAjaxComplete: (resp: any) => {
        const saved = submitResponseParser ? submitResponseParser(resp) : resp;
        if (isNew && props.onPostComplete) {
          props.onPostComplete(saved);
        } else if (props.onPutComplete) {
          props.onPutComplete(saved);
        }
        if (done) done();
      }
    };
    handleSubmission(submission);
  }

  function handleDelete() {
    const delUrl = `${url}/${data[primaryKey]}`;
    del(delUrl, (resp) => {
      console.log('deleted', resp);
      Toast.success('Operation succeeded.');
      props.onDeleteComplete(data);
    });
  }

  return (
    <XForm
      onSubmit={handleSubmit}
      onDelete={isDeletable ? undefined : handleDelete}
      isDeletable={isDeletable}
      schema={schemaObject}
      initialValues={{ ...initialValues, ...data }}
      debug={debug}
      onCancel={onCancel}
    >
      <Grid
        spacing={3}
        container
      >
        {
            formFields.map(({ inputProps, ...rootProps }) => {
              const { gridProps, ...rest } = inputProps;
              const formProps = { ...rootProps, inputProps: rest };
              return (
                <Grid
                  item
                  xs={12}
                  {...gridProps}
                  key={formProps.name}
                >
                  {renderInput(formProps)}
                </Grid>
              );
            })
          }
      </Grid>
    </XForm>
  );
};

export default EditForm;
