import React from 'react';
import { useField } from 'formik';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { hasValue } from './inputHelpers';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';

interface IProps {
  name: string
  label: string
  disabled?: boolean
}

const XCheckBoxInput = (props: IProps) => {
  const { name, label, disabled } = props;
  const [field, meta] = useField({ name });
  const error = hasValue(meta.error) ? meta.error : undefined;
  const showError = Boolean(error);

  return (
      <FormControl
          error={showError}
      >
        <FormControlLabel
            label={label}
            control={(
                <Checkbox
                    checked={Boolean(field.value)}
                    value={Boolean(field.value)}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    name={name}
                    color="primary"
                />
            )}
            disabled={disabled ?? false}
        />
        {
            showError && <FormHelperText>{error}</FormHelperText>
        }
      </FormControl>
  );
};
export default XCheckBoxInput;
