/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Address {
  country?: string | null;
  district?: string | null;
  parish?: string | null;
  plot?: string | null;
  postalAddress?: string | null;
  region?: string | null;
  street?: string | null;
  subCountry?: string | null;
  village?: string | null;
}

export interface AmlScreeningCreateModel {
  referenceId?: string | null;
  /** @format uuid */
  bulkId?: string | null;
  /** Determine if we should execute this call in the background */
  isBlocking?: boolean;
  /**
   * The customer for who this request is being made against
   * @format uuid
   */
  customerId?: string | null;
  category: CustomerCategory;
  /**
   * string that is checked against sanctions list
   * @minLength 1
   */
  names: string;
  gender?: CustomerGender;
  /**
   * Include DateOfBirth in the screening for CustomerCategory.Person
   * @format date
   */
  dateOfBirth?: string | null;
  /**
   * This parameter defines if spelling differences need to be considered for the search string.
   *
   * 1: Fuzzy search with distance 1 (e.g. Angela will also match for Angelo) for the "names" parameter.
   *
   * 2: Fuzzy search with distance 2 (e.g. Angela will also match for Angale) for the "names" parameter.
   * @format int32
   */
  fuzzySearch?: number | null;
}

export interface AmlScreeningCreateModelBulkVerificationPreviewResponse {
  /** @format uuid */
  docId?: string;
  document?: DocViewModel;
  /** @format int32 */
  totalRecords?: number;
  errors?: ImportError[] | null;
  sample?: AmlScreeningCreateModel[] | null;
}

export enum AmlScreeningStatus {
  NoHits = "NoHits",
  HasHits = "HasHits",
  Failed = "Failed",
}

export interface AmlScreeningViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  /** @format uuid */
  partnerId?: string;
  partner?: ComboModel;
  /** @format date-time */
  sentAt?: string | null;
  /** @format uuid */
  productId?: string;
  /** @format uuid */
  bulkId?: string | null;
  /** @format uuid */
  customerId?: string | null;
  status?: RequestStatus;
  /** @format date-time */
  startedAt?: string | null;
  /** @format int64 */
  runningBalance?: number;
  /** @format date-time */
  processedAt?: string | null;
  /** @format int32 */
  attemptCount?: number;
  channel?: string | null;
  requestedBy?: string | null;
  requestedByData?: ComboModel;
  referenceId?: string | null;
  errorCode?: string | null;
  errorMessage?: string | null;
  names?: string | null;
  /** @format int32 */
  fuzzySearch?: number | null;
  /** @format date */
  dateOfBirth?: string | null;
  gender?: CustomerGender;
  includes?: string[] | null;
  verificationStatus?: AmlScreeningStatus;
  /** @format int32 */
  totalHits?: number | null;
  /** @format date-time */
  timestamp?: string | null;
  category?: CustomerCategory;
  individualRecords?: IndividualFoundRecord[] | null;
  entityRecords?: EntityFoundRecord[] | null;
}

export interface AmlScreeningViewModelPaginatedListViewModel {
  /** @format int32 */
  page?: number;
  /** @format int32 */
  totalPages?: number;
  /** @format int32 */
  total?: number;
  data?: AmlScreeningViewModel[] | null;
  hasPreviousPage?: boolean;
  hasNextPage?: boolean;
}

export interface ApiKeyResetResponse {
  key?: string | null;
}

export interface ApiKeyViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  ownerName?: string | null;
  /** @format uuid */
  partnerId?: string;
  isActive?: boolean;
  role?: string | null;
  /** @format date-time */
  lastUsedAt?: string | null;
}

export enum AuthMode {
  Jwt = "Jwt",
  ApiKey = "ApiKey",
}

export interface BaseApproveModel {
  /** @format uuid */
  id?: string;
  /** @minLength 1 */
  remarks: string;
  decision?: boolean;
}

export interface BaseResponse {
  message?: string | null;
  status?: boolean;
  data?: Record<string, any>;
}

export interface BulkAmlScreeningCreateModel {
  category: CustomerCategory;
  /** @minItems 1 */
  selectedCustomerIds: string[];
  /** The reference id that will be used to identify the request */
  referenceId?: string | null;
  /** Set to true if you include the gender in the screening */
  includeGender?: boolean | null;
  /** Set to true if you include the date of birth in the screening */
  includeDateOfBirth?: boolean | null;
}

export interface BulkAmlScreeningImportRequest {
  /**
   * The document to be used
   * @format uuid
   */
  docId?: string;
  externalReference?: string | null;
  message?: string | null;
}

export interface BulkBusinessSearchCreateModel {
  /** @minItems 1 */
  selectedCustomerIds: string[];
  /** The reference id that will be used to identify the request */
  referenceId?: string | null;
}

export interface BulkBusinessSearchImportRequest {
  /**
   * The document to be used
   * @format uuid
   */
  docId?: string;
  externalReference?: string | null;
  message?: string | null;
}

export interface BulkConsentRequestCreateModel {
  selectedCustomerIds?: string[] | null;
  /**
   * The template that is used to generate the document.
   * @format uuid
   */
  templateId?: string;
  /** The reference id that will be used to identify the consent request */
  referenceId?: string | null;
  /** Set to true if you want to send a consent request via email */
  includeEmail?: boolean;
}

export interface BulkConsentRequestEditModel {
  /** @format uuid */
  id?: string;
}

export interface BulkConsentRequestImportRequest {
  /**
   * The document to be used
   * @format uuid
   */
  docId?: string;
  externalReference?: string | null;
  message?: string | null;
  /** @format uuid */
  templateId?: string;
}

export interface BulkPhoneNumberVerificationImportRequest {
  /**
   * The document to be used
   * @format uuid
   */
  docId?: string;
  externalReference?: string | null;
  message?: string | null;
}

export interface BulkVerificationImportRequest {
  /**
   * The document to be used
   * @format uuid
   */
  docId?: string;
  externalReference?: string | null;
  message?: string | null;
}

export enum BulkVerificationStatus {
  Pending = "Pending",
  Processing = "Processing",
  Completed = "Completed",
  Error = "Error",
}

export interface BulkVerifyPersonInformationImportRequest {
  /**
   * The document to be used
   * @format uuid
   */
  docId?: string;
  externalReference?: string | null;
  message?: string | null;
}

export interface BulkVerifyPersonInformationPreview {
  /** @format uuid */
  docId?: string;
  document?: DocViewModel;
  /** @format int32 */
  totalRecords?: number;
  errors?: ImportError[] | null;
  sample?: VerifyPersonInformationRequestModel[] | null;
}

export interface BulkVerifyPersonInformationViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  /** @format uuid */
  partnerId?: string;
  partner?: ComboModel;
  /** @maxLength 50 */
  requestedBy?: string | null;
  requestedByData?: ComboModel;
  status?: BulkVerificationStatus;
  /** @format int32 */
  totalRequests?: number;
  /** @format int32 */
  completedRequests?: number;
  /** @format int32 */
  failedRequests?: number;
  /** @format date-time */
  startedAt?: string | null;
  /** @format date-time */
  completedAt?: string | null;
  externalReference?: string | null;
  /** @format uuid */
  documentId?: string | null;
  document?: Doc;
  message?: string | null;
}

export interface BulkVerifyPersonInformationViewModelPaginatedListViewModel {
  /** @format int32 */
  page?: number;
  /** @format int32 */
  totalPages?: number;
  /** @format int32 */
  total?: number;
  data?: BulkVerifyPersonInformationViewModel[] | null;
  hasPreviousPage?: boolean;
  hasNextPage?: boolean;
}

export enum BusinessSearchCategory {
  FullEntity = "FullEntity",
  BasicEntity = "BasicEntity",
}

export interface BusinessSearchCreateModel {
  referenceId?: string | null;
  /** @format uuid */
  bulkId?: string | null;
  /** Determine if we should execute this call in the background */
  isBlocking?: boolean;
  /**
   * The customer for who this request is being made against
   * @format uuid
   */
  customerId?: string | null;
  category?: BusinessSearchCategory;
  /**
   * string that is checked against sanctions list
   * @minLength 1
   */
  businessRegistrationNumber: string;
}

export interface BusinessSearchCreateModelBulkVerificationPreviewResponse {
  /** @format uuid */
  docId?: string;
  document?: DocViewModel;
  /** @format int32 */
  totalRecords?: number;
  errors?: ImportError[] | null;
  sample?: BusinessSearchCreateModel[] | null;
}

export enum BusinessSearchStatus {
  NotFound = "NotFound",
  Incorporated = "Incorporated",
  Dissolved = "Dissolved",
  Error = "Error",
}

export interface BusinessSearchViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  /** @format uuid */
  partnerId?: string;
  partner?: ComboModel;
  /** @format date-time */
  sentAt?: string | null;
  /** @format uuid */
  productId?: string;
  /** @format uuid */
  bulkId?: string | null;
  /** @format uuid */
  customerId?: string | null;
  status?: RequestStatus;
  /** @format date-time */
  startedAt?: string | null;
  /** @format int64 */
  runningBalance?: number;
  /** @format date-time */
  processedAt?: string | null;
  /** @format int32 */
  attemptCount?: number;
  channel?: string | null;
  requestedBy?: string | null;
  requestedByData?: ComboModel;
  referenceId?: string | null;
  errorCode?: string | null;
  errorMessage?: string | null;
  category?: BusinessSearchCategory;
  businessRegistrationNumber?: string | null;
  subType?: string | null;
  registrationStatus?: string | null;
  entityName?: string | null;
  dissolutionDate?: string | null;
  incorporationDate?: string | null;
  registrationDate?: string | null;
  searchStatus?: BusinessSearchStatus;
  responseData?: UrsbEntityFullResponseData;
}

export interface BusinessSearchViewModelPaginatedListViewModel {
  /** @format int32 */
  page?: number;
  /** @format int32 */
  totalPages?: number;
  /** @format int32 */
  total?: number;
  data?: BusinessSearchViewModel[] | null;
  hasPreviousPage?: boolean;
  hasNextPage?: boolean;
}

export interface ComboModel {
  id?: string | null;
  name?: string | null;
}

export enum CompanyCustomerCategory {
  PrivateLimited = "PrivateLimited",
  PublicLimited = "PublicLimited",
  SoleProprietorship = "SoleProprietorship",
  Partnership = "Partnership",
  Other = "Other",
}

export interface CompanyCustomerCreateModel {
  /** @format uuid */
  id?: string | null;
  category?: CompanyCustomerCategory;
  /** @minLength 1 */
  name: string;
  email?: string | null;
  phone?: string | null;
  contactPersonPhone?: string | null;
  contactPersonEmail?: string | null;
  contactPersonName?: string | null;
  /** @minLength 1 */
  registrationNumber: string;
  /** @format date */
  registrationDate?: string | null;
  tinNumber?: string | null;
  /** @format uuid */
  customerPersonId?: string | null;
  /** @format uuid */
  accountManagerId?: string | null;
}

export interface CompanyCustomerViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  category?: CompanyCustomerCategory;
  name?: string | null;
  registrationNumber?: string | null;
  /** @format date */
  registrationDate?: string | null;
  /** @format int32 */
  numberOfEmployees?: number | null;
  /** @format uuid */
  customerId?: string;
  contactPersonName?: string | null;
  contactPersonPhone?: string | null;
  contactPersonEmail?: string | null;
}

export interface CompanyInfo {
  name?: string | null;
  email?: string | null;
  phone?: string | null;
}

export enum ConsentChannel {
  Web = "Web",
  Ussd = "Ussd",
  MobileApp = "MobileApp",
  System = "System",
  Other = "Other",
}

export interface ConsentDataCustodianCreateModel {
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  email: string;
  isPublic?: boolean;
  description?: string | null;
  dataIdentificationCategories?: DataIdentifierCategory[] | null;
  dataPoints?: string[] | null;
  dataPointsCsv?: string | null;
}

export interface ConsentDataCustodianEditModel {
  /** @format uuid */
  id?: string;
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  email: string;
  isPublic?: boolean;
  description?: string | null;
  dataIdentificationCategories?: DataIdentifierCategory[] | null;
  dataPoints?: string[] | null;
  dataPointsCsv?: string | null;
}

export interface ConsentDataCustodianViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  /** @format uuid */
  partnerId?: string;
  partner?: ComboModel;
  /** @maxLength 50 */
  requestedBy?: string | null;
  requestedByData?: ComboModel;
  name?: string | null;
  email?: string | null;
  isPublic?: boolean;
  description?: string | null;
  dataIdentificationCategories?: DataIdentifierCategory[] | null;
  dataPoints?: string[] | null;
}

export interface ConsentField {
  /** @format uuid */
  id?: string;
  name: string | null;
  label: string | null;
  type: ConsentFieldType;
  optionsCsv?: string | null;
  required?: boolean;
  columnWidth?: string | null;
}

export enum ConsentFieldType {
  Text = "Text",
  Number = "Number",
  Date = "Date",
  Checkbox = "Checkbox",
  Select = "Select",
}

export interface ConsentFingerPrintMatchStatus {
  transactionReference?: string | null;
  transactionStatus?: string | null;
  consentId?: string | null;
  message?: string | null;
  encodedData?: string | null;
}

export interface ConsentProcessApproveModel {
  /** @format uuid */
  requestId?: string;
  referenceId?: string | null;
  memo?: string | null;
  channel?: ConsentChannel;
  metaData?: Record<string, string>;
}

export interface ConsentRequestComboModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  /**
   * The id of the partner requesting the consent.
   * @format uuid
   */
  partnerId?: string;
  partner?: XContactModel;
  /** A unique reference id from the external system. */
  referenceId?: string | null;
  /** A representation of the customer in the external system. */
  consentId?: string | null;
  dataIdentifier?: string | null;
  fullName?: string | null;
  ussdRequestMessage?: string | null;
  ussdApprovalMessage?: string | null;
  ussdRejectionMessage?: string | null;
  metaData?: Record<string, string>;
  requiresOtp?: boolean;
}

export interface ConsentRequestCreateModel {
  referenceId?: string | null;
  /** @format uuid */
  bulkId?: string | null;
  metaData?: Record<string, string>;
  /**
   * The template that is being used to request for consent.
   * @format uuid
   */
  templateId?: string;
  /**
   * @format tel
   * @minLength 1
   */
  phoneNumber: string;
  /** @format email */
  email?: string | null;
  /**
   * Identifier for the entity who owns the data.
   * @minLength 1
   * @maxLength 150
   */
  dataIdentifier: string;
}

export interface ConsentRequestCreateModelBulkVerificationPreviewResponse {
  /** @format uuid */
  docId?: string;
  document?: DocViewModel;
  /** @format int32 */
  totalRecords?: number;
  errors?: ImportError[] | null;
  sample?: ConsentRequestCreateModel[] | null;
}

export interface ConsentRequestLogViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  /** @format uuid */
  requestId?: string;
  status?: ConsentStatus;
  consentChannel?: ConsentChannel;
  /** @maxLength 50 */
  memo?: string | null;
  /** @maxLength 200 */
  details?: string | null;
  /** @maxLength 50 */
  userId?: string | null;
}

export interface ConsentRequestViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  /** @format uuid */
  partnerId?: string;
  partner?: ComboModel;
  /** @format date-time */
  sentAt?: string | null;
  /** @format uuid */
  productId?: string;
  /** @format uuid */
  bulkId?: string | null;
  /** @format uuid */
  customerId?: string | null;
  status?: RequestStatus;
  /** @format date-time */
  startedAt?: string | null;
  /** @format int64 */
  runningBalance?: number;
  /** @format date-time */
  processedAt?: string | null;
  /** @format int32 */
  attemptCount?: number;
  channel?: string | null;
  requestedBy?: string | null;
  requestedByData?: ComboModel;
  referenceId?: string | null;
  errorCode?: string | null;
  errorMessage?: string | null;
  consentStatus?: ConsentStatus;
  /**
   * The time when the consent sms was sent to the customer.
   * @format date-time
   */
  smsSentAt?: string | null;
  /**
   * The time when the consent email was sent to the customer.
   * @format date-time
   */
  emailSentAt?: string | null;
  /**
   * Time when the person responded to the request.
   * @format date-time
   */
  respondedOn?: string | null;
  /**
   * The person or system responding to the request.
   * @maxLength 50
   */
  responseBy?: string | null;
  /**
   * This is the reference id from the external system.
   * This could be the sessionID, transactionID, etc.
   * @maxLength 50
   */
  responseReferenceId?: string | null;
  /** @maxLength 100 */
  responseMemo?: string | null;
  phoneNumber?: string | null;
  email?: string | null;
  verifiedFullname?: string | null;
  /**
   * What data points are being consented to.
   * * NIN- Biodata, Fingerprint, photo
   * * Tin - Biodata, photo,tax clearance,employer
   * * Farmer - Biodata, photo, farm location, farm size, farm produce
   * @maxLength 50
   */
  dataPoints?: string | null;
  /** @maxLength 500 */
  detailedDataPoints?: string | null;
  /**
   * Identifier for the entity who owns the data.
   * @maxLength 150
   */
  dataIdentifier?: string | null;
  metaData?: Record<string, string>;
  /**
   * The entity that is holding the data.
   * @format uuid
   */
  dataHolderId?: string;
  consentId?: string | null;
  /**
   * The template that is being used to request for consent.
   * @format uuid
   */
  templateId?: string | null;
  template?: ComboModel;
}

export interface ConsentRequestViewModelPaginatedListViewModel {
  /** @format int32 */
  page?: number;
  /** @format int32 */
  totalPages?: number;
  /** @format int32 */
  total?: number;
  data?: ConsentRequestViewModel[] | null;
  hasPreviousPage?: boolean;
  hasNextPage?: boolean;
}

export enum ConsentStatus {
  Created = "Created",
  Sent = "Sent",
  Approved = "Approved",
  Rejected = "Rejected",
  Revoked = "Revoked",
  Failed = "Failed",
  Canceled = "Canceled",
  Expired = "Expired",
}

export interface ConsentTemplateComboModel {
  id?: string | null;
  name?: string | null;
  /** @format uuid */
  dataCustodianId?: string;
  dataCustodian?: ComboModel;
  fields?: ConsentField[] | null;
}

export interface ConsentTemplateCreateModel {
  /** @format uuid */
  dataCustodianId?: string;
  /**
   * @minLength 1
   * @maxLength 40
   */
  name: string;
  /**
   * @minLength 1
   * @maxLength 800
   */
  requestMessage: string;
  /**
   * @minLength 1
   * @maxLength 800
   */
  approvalMessage: string;
  /**
   * @minLength 1
   * @maxLength 800
   */
  rejectionMessage: string;
  /** @maxLength 1600 */
  detailedRequestMessage?: string | null;
  /** @maxLength 1600 */
  detailedApprovalMessage?: string | null;
  /** @maxLength 1600 */
  detailedRejectionMessage?: string | null;
  /**
   * @minLength 1
   * @maxLength 200
   */
  ussdRequestMessage: string;
  /**
   * @minLength 1
   * @maxLength 200
   */
  ussdApprovalMessage: string;
  /**
   * @minLength 1
   * @maxLength 200
   */
  ussdRejectionMessage: string;
  dataPoints?: string | null;
  /** @format uuid */
  pdfTemplateId?: string | null;
  requiresOtp?: boolean;
  preFetchDataFromCustodian?: boolean;
  postFetchDataFromCustodian?: boolean;
  skipPhoneVerification?: boolean;
  skipRequestSentNotification?: boolean;
  skipRequestCompleteNotification?: boolean;
  /** @format int32 */
  expiryInMinutes?: number;
  fields?: ConsentField[] | null;
  consentChannels?: ConsentChannel[] | null;
}

export interface ConsentTemplateEditModel {
  /** @format uuid */
  id?: string;
  /** @format uuid */
  dataCustodianId?: string;
  /**
   * @minLength 1
   * @maxLength 100
   */
  name: string;
  /**
   * @minLength 1
   * @maxLength 800
   */
  requestMessage: string;
  /**
   * @minLength 1
   * @maxLength 800
   */
  approvalMessage: string;
  /**
   * @minLength 1
   * @maxLength 800
   */
  rejectionMessage: string;
  /** @maxLength 1600 */
  detailedRequestMessage?: string | null;
  /** @maxLength 1600 */
  detailedApprovalMessage?: string | null;
  /** @maxLength 1600 */
  detailedRejectionMessage?: string | null;
  /**
   * @minLength 1
   * @maxLength 200
   */
  ussdRequestMessage: string;
  /**
   * @minLength 1
   * @maxLength 200
   */
  ussdApprovalMessage: string;
  /**
   * @minLength 1
   * @maxLength 200
   */
  ussdRejectionMessage: string;
  dataPoints?: string | null;
  /** @format uuid */
  pdfTemplateId?: string | null;
  requiresOtp?: boolean;
  preFetchDataFromCustodian?: boolean;
  postFetchDataFromCustodian?: boolean;
  skipPhoneVerification?: boolean;
  skipRequestSentNotification?: boolean;
  skipRequestCompleteNotification?: boolean;
  /** @format int32 */
  expiryInMinutes?: number;
  fields?: ConsentField[] | null;
  consentChannels?: ConsentChannel[] | null;
}

export interface ConsentTemplateViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  /** @format uuid */
  partnerId?: string;
  partner?: ComboModel;
  /** @maxLength 50 */
  requestedBy?: string | null;
  requestedByData?: ComboModel;
  dataIdentifierCategory?: DataIdentifierCategory;
  /** @format uuid */
  dataCustodianId?: string;
  dataCustodian?: ConsentDataCustodianViewModel;
  name?: string | null;
  requestMessage?: string | null;
  approvalMessage?: string | null;
  rejectionMessage?: string | null;
  detailedRequestMessage?: string | null;
  detailedApprovalMessage?: string | null;
  detailedRejectionMessage?: string | null;
  ussdRequestMessage?: string | null;
  ussdApprovalMessage?: string | null;
  ussdRejectionMessage?: string | null;
  dataPoints?: string | null;
  requiresOtp?: boolean;
  preFetchDataFromCustodian?: boolean;
  postFetchDataFromCustodian?: boolean;
  skipPhoneVerification?: boolean;
  skipRequestSentNotification?: boolean;
  skipRequestCompleteNotification?: boolean;
  /** @format int32 */
  expiryInMinutes?: number;
  fields?: ConsentField[] | null;
  consentChannels?: ConsentChannel[] | null;
  /** @format uuid */
  pdfTemplateId?: string | null;
  pdfTemplate?: DocViewModel;
}

export interface ContactMiniViewModel {
  /** @format uuid */
  id?: string;
  /** @format uuid */
  contactPersonId?: string | null;
  name: string | null;
  email?: string | null;
  phone?: string | null;
}

export interface Corporate {
  businessRegistrationNumber?: string | null;
  companyType?: string | null;
  dateOfIncorporation?: string | null;
  entityName?: string | null;
  officialEmail?: string | null;
  officialTelephoneNumber?: string | null;
}

export enum CustomerAddressCategory {
  Home = "Home",
  Work = "Work",
  Other = "Other",
}

export interface CustomerAddressCreateModel {
  category?: CustomerAddressCategory;
  /** @format uuid */
  customerId?: string;
  country?: string | null;
  district?: string | null;
  county?: string | null;
  subCounty?: string | null;
  village?: string | null;
  parish?: string | null;
  postalCode?: string | null;
  street?: string | null;
  freeForm?: string | null;
  placeId?: string | null;
  latLon?: string | null;
  /** @format date-time */
  startDate?: string | null;
  /** @format date-time */
  endDate?: string | null;
  isPrimary?: boolean;
}

export interface CustomerAddressEditModel {
  category?: CustomerAddressCategory;
  /** @format uuid */
  customerId?: string;
  country?: string | null;
  district?: string | null;
  county?: string | null;
  subCounty?: string | null;
  village?: string | null;
  parish?: string | null;
  postalCode?: string | null;
  street?: string | null;
  freeForm?: string | null;
  placeId?: string | null;
  latLon?: string | null;
  /** @format date-time */
  startDate?: string | null;
  /** @format date-time */
  endDate?: string | null;
  isPrimary?: boolean;
  /** @format uuid */
  id?: string;
}

export interface CustomerAddressViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  category?: CustomerAddressCategory;
  /** @format uuid */
  customerId?: string;
  country?: string | null;
  district?: string | null;
  county?: string | null;
  subCounty?: string | null;
  village?: string | null;
  parish?: string | null;
  postalCode?: string | null;
  street?: string | null;
  freeForm?: string | null;
  placeId?: string | null;
  latLon?: string | null;
  /** @format date-time */
  startDate?: string | null;
  /** @format date-time */
  endDate?: string | null;
  isPrimary?: boolean;
}

export enum CustomerCategory {
  Person = "Person",
  Company = "Company",
}

export enum CustomerCivilStatus {
  Single = "Single",
  Married = "Married",
  Divorced = "Divorced",
  Other = "Other",
}

/** Model to create a consent request by an existing customer */
export interface CustomerConsentRequestCreateModel {
  referenceId?: string | null;
  /** @format uuid */
  bulkId?: string | null;
  metaData?: Record<string, string>;
  /**
   * The template that is being used to request for consent.
   * @format uuid
   */
  templateId?: string;
  /**
   * The customer who will give consent
   * @format uuid
   */
  customerId?: string;
}

export enum CustomerEmailCategory {
  Personal = "Personal",
  Work = "Work",
  Other = "Other",
}

export interface CustomerEmailCreateModel {
  category?: CustomerEmailCategory;
  /** @format uuid */
  customerId?: string;
  value?: string | null;
  isPrimary?: boolean;
}

export interface CustomerEmailEditModel {
  category?: CustomerEmailCategory;
  /** @format uuid */
  customerId?: string;
  value?: string | null;
  isPrimary?: boolean;
  /** @format uuid */
  id?: string;
}

export interface CustomerEmailViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  category?: CustomerEmailCategory;
  /** @format uuid */
  customerId?: string;
  value?: string | null;
  isPrimary?: boolean;
}

export enum CustomerGender {
  Female = "Female",
  Male = "Male",
}

export enum CustomerIdentificationCategory {
  NIN = "NIN",
  BRN = "BRN",
  TIN = "TIN",
  FCN = "FCN",
  Passport = "Passport",
  DrivingPermit = "DrivingPermit",
  VillageCard = "VillageCard",
  NSSF = "NSSF",
  Other = "Other",
}

export interface CustomerIdentificationCreateModel {
  category?: CustomerIdentificationCategory;
  /** @format uuid */
  customerId?: string;
  documentId?: string | null;
  value?: string | null;
  issuingCountry?: string | null;
  /** @format date-time */
  issueDate?: string | null;
  /** @format date-time */
  expiryDate?: string | null;
  isPrimary?: boolean;
}

export interface CustomerIdentificationEditModel {
  category?: CustomerIdentificationCategory;
  /** @format uuid */
  customerId?: string;
  documentId?: string | null;
  value?: string | null;
  issuingCountry?: string | null;
  /** @format date-time */
  issueDate?: string | null;
  /** @format date-time */
  expiryDate?: string | null;
  isPrimary?: boolean;
  /** @format uuid */
  id?: string;
}

export interface CustomerIdentificationViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  category?: CustomerIdentificationCategory;
  /** @format uuid */
  customerId?: string;
  documentId?: string | null;
  value?: string | null;
  issuingCountry?: string | null;
  /** @format date-time */
  issueDate?: string | null;
  /** @format date-time */
  expiryDate?: string | null;
  isPrimary?: boolean;
}

export interface CustomerMiniViewModel {
  /** @format uuid */
  id?: string;
  name?: string | null;
  email?: string | null;
  phone?: string | null;
}

export interface CustomerMiniViewModelPaginatedListViewModel {
  /** @format int32 */
  page?: number;
  /** @format int32 */
  totalPages?: number;
  /** @format int32 */
  total?: number;
  data?: CustomerMiniViewModel[] | null;
  hasPreviousPage?: boolean;
  hasNextPage?: boolean;
}

export enum CustomerPhoneCategory {
  Mobile = "Mobile",
  Office = "Office",
  Home = "Home",
  Fax = "Fax",
  Other = "Other",
}

export interface CustomerPhoneCreateModel {
  /** @format uuid */
  customerId?: string;
  category?: CustomerPhoneCategory;
  value?: string | null;
  isPrimary?: boolean;
}

export interface CustomerPhoneEditModel {
  /** @format uuid */
  customerId?: string;
  category?: CustomerPhoneCategory;
  value?: string | null;
  isPrimary?: boolean;
  /** @format uuid */
  id?: string;
}

export interface CustomerPhoneViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  /** @format uuid */
  customerId?: string;
  category?: CustomerPhoneCategory;
  value?: string | null;
  isPrimary?: boolean;
}

export enum CustomerSalutation {
  Mr = "Mr",
  Mrs = "Mrs",
  Ms = "Ms",
  Dr = "Dr",
  Prof = "Prof",
  Other = "Other",
}

export interface CustomerTagCreateModel {
  /** @format uuid */
  customerId?: string;
  value?: string | null;
}

export interface CustomerTagEditModel {
  /** @format uuid */
  customerId?: string;
  value?: string | null;
  /** @format uuid */
  id?: string;
}

export interface CustomerTagViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  /** @format uuid */
  customerId?: string;
  value?: string | null;
}

export enum CustomerUrlCategory {
  Website = "Website",
  Facebook = "Facebook",
  Linkedin = "Linkedin",
  Twitter = "Twitter",
  Other = "Other",
}

export interface CustomerUrlCreateModel {
  /** @format uuid */
  customerId?: string;
  category?: CustomerUrlCategory;
  value?: string | null;
}

export interface CustomerUrlEditModel {
  /** @format uuid */
  customerId?: string;
  category?: CustomerUrlCategory;
  value?: string | null;
  /** @format uuid */
  id?: string;
}

export interface CustomerUrlViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  /** @format uuid */
  customerId?: string;
  category?: CustomerUrlCategory;
  value?: string | null;
}

export interface CustomerViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  category?: CustomerCategory;
  person?: PersonCustomerViewModel;
  company?: CompanyCustomerViewModel;
  /** @format uuid */
  partnerId?: string;
  partner?: CustomerMiniViewModel;
  identifications?: CustomerIdentificationViewModel[] | null;
  phones?: CustomerPhoneViewModel[] | null;
  emails?: CustomerEmailViewModel[] | null;
  addresses?: CustomerAddressViewModel[] | null;
  urls?: CustomerUrlViewModel[] | null;
  tags?: CustomerTagViewModel[] | null;
}

export interface CustomerViewModelPaginatedListViewModel {
  /** @format int32 */
  page?: number;
  /** @format int32 */
  totalPages?: number;
  /** @format int32 */
  total?: number;
  data?: CustomerViewModel[] | null;
  hasPreviousPage?: boolean;
  hasNextPage?: boolean;
}

export enum DataIdentifierCategory {
  NIN = "NIN",
  BRN = "BRN",
  TIN = "TIN",
  FCN = "FCN",
  Passport = "Passport",
  DrivingPermit = "DrivingPermit",
  NSSF = "NSSF",
  Other = "Other",
  Email = "Email",
  Phone = "Phone",
  EmployeeNumber = "EmployeeNumber",
  VillageCard = "VillageCard",
}

export interface DilisenseSource {
  sourceId?: string | null;
  name?: string | null;
  description?: string | null;
  /** @format uri */
  link?: string | null;
  countryName?: string | null;
}

export interface Director {
  corporate?: Corporate;
  individual?: Individual;
  type?: string | null;
}

export interface Doc {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  lastUpdated?: string | null;
  isDeleted?: boolean;
  name?: string | null;
  contentType?: string | null;
  originalName?: string | null;
  description?: string | null;
  size?: string | null;
  /** @format uuid */
  referenceId?: string | null;
  /** @format uuid */
  partnerId?: string;
}

export interface DocViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  name?: string | null;
  contentType?: string | null;
  originalName?: string | null;
  description?: string | null;
  size?: string | null;
  /** @format uuid */
  partnerId?: string;
}

export interface EditModel {
  /** @format uuid */
  id?: string;
}

export interface EntityFoundRecord {
  sourceType?: string | null;
  entityType?: string | null;
  sourceId?: string | null;
  name?: string | null;
  aliasNames?: string[] | null;
  address?: string[] | null;
  sanctionDetails?: string[] | null;
  listDate?: string | null;
  source?: DilisenseSource;
}

export enum HttpVerb {
  Post = "Post",
}

export interface ImportError {
  /** @format int32 */
  row?: number;
  /** @format int32 */
  col?: number;
  error?: string | null;
}

export interface Individual {
  givenName?: string | null;
  idNumber?: string | null;
  idType?: string | null;
  middleName?: string | null;
  surname?: string | null;
}

export interface IndividualFoundRecord {
  entityType?: string | null;
  name?: string | null;
  sourceId?: string | null;
  aliasNames?: string[] | null;
  dateOfBirth?: string[] | null;
  citizenship?: string[] | null;
  occupations?: string[] | null;
  politicalParties?: string[] | null;
  sourceType?: string | null;
  pepType?: string | null;
  listDate?: string | null;
  gender?: string | null;
  description?: string[] | null;
  givenNames?: string[] | null;
  placeOfBirth?: string[] | null;
  spouse?: string[] | null;
  siblings?: string[] | null;
  parents?: string[] | null;
  lastNames?: string[] | null;
  aliasGivenNames?: string[] | null;
  citizenshipRemarks?: string[] | null;
  sanctionDetails?: string[] | null;
  otherInformation?: string[] | null;
  links?: string[] | null;
  address?: string[] | null;
  titles?: string[] | null;
  source?: DilisenseSource;
}

export enum IoTecPayCollectionStatus {
  Pending = "Pending",
  SentToVendor = "SentToVendor",
  Success = "Success",
  Failed = "Failed",
}

export interface IoTecPayCreditTopUpResponse {
  /** @format uuid */
  transactionId?: string;
  payer?: string | null;
  status?: IoTecPayCollectionStatus;
  currency?: string | null;
  /** @format double */
  amount?: number;
  statusCode?: string | null;
  statusMessage?: string | null;
  /** @format double */
  totalTransactionCharge?: number | null;
}

export interface NotFoundResult {
  /** @format int32 */
  statusCode?: number;
}

export interface OkResult {
  /** @format int32 */
  statusCode?: number;
}

export interface PartnerCallbackCreateModel {
  category?: ProductCategory;
  httpVerb?: HttpVerb;
  /** @minLength 1 */
  endpointUrl: string;
  /** @format uuid */
  partnerId?: string;
  credentials?: PartnerCallbackCredential[] | null;
}

export interface PartnerCallbackCredential {
  header?: string | null;
  value?: string | null;
}

export interface PartnerCallbackCredentialViewModel {
  header?: string | null;
}

export interface PartnerCallbackUpdateModel {
  /** @format uuid */
  id?: string;
  category?: ProductCategory;
  httpVerb?: HttpVerb;
  /** @minLength 1 */
  endpointUrl: string;
  credentials?: PartnerCallbackCredential[] | null;
}

export interface PartnerCallbackViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  category?: ProductCategory;
  httpVerb?: HttpVerb;
  endpointUrl?: string | null;
  credentials?: PartnerCallbackCredentialViewModel[] | null;
  /** @format uuid */
  partnerId?: string;
  createdByData?: ComboModel;
}

export interface PartnerConsentRequestCreateModel {
  referenceId?: string | null;
  /** @format uuid */
  bulkId?: string | null;
  metaData?: Record<string, string>;
  /**
   * The template that is being used to request for consent.
   * @format uuid
   */
  templateId?: string;
  /**
   * @format tel
   * @minLength 1
   */
  phoneNumber: string;
  /** @format email */
  email?: string | null;
  /**
   * Identifier for the entity who owns the data.
   * @minLength 1
   * @maxLength 150
   */
  dataIdentifier: string;
  /** @format uuid */
  partnerId?: string;
}

export interface PartnerCreateModel {
  /** @format uuid */
  id: string;
  partnerIps: string[];
}

export interface PartnerIpViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  /** @minLength 1 */
  ipAddress: string;
}

export interface PartnerMemberViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  partner?: ComboModel;
  person?: ContactMiniViewModel;
  /** @format uuid */
  partnerId?: string;
  active?: boolean;
  roles?: string[] | null;
}

export interface PartnerUpdateModel {
  /** @format uuid */
  id?: string;
  active?: boolean;
  company?: CompanyInfo;
  partnerIps: string[];
}

export interface PartnerViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  clientId?: string | null;
  name?: string | null;
  active?: boolean;
  company?: XContactModel;
  members?: PartnerMemberViewModel[] | null;
  partnerIps?: PartnerIpViewModel[] | null;
  apiKey?: ApiKeyViewModel;
  products?: ProductViewModel[] | null;
}

export interface PersonCreateModel {
  /** @minLength 1 */
  surname: string;
  /** @minLength 1 */
  givenNames: string;
  nationality?: string | null;
  gender?: CustomerGender;
  /** @format date */
  dateOfBirth?: string | null;
  civilStatus?: CustomerCivilStatus;
  salutation?: CustomerSalutation;
  /** @format email */
  email?: string | null;
  /** @format tel */
  phone?: string | null;
  /** @format tel */
  secondaryPhone?: string | null;
  tinNumber?: string | null;
  idCategory?: CustomerIdentificationCategory;
  idNumber?: string | null;
  idDocumentNumber?: string | null;
  /** @format date-time */
  idIssueDate?: string | null;
  /** @format date-time */
  idExpiryDate?: string | null;
  /** @format uuid */
  partnerId?: string | null;
  profession?: string | null;
  externalId?: string | null;
  tags?: string | null;
}

export interface PersonCreateModelBulkVerificationPreviewResponse {
  /** @format uuid */
  docId?: string;
  document?: DocViewModel;
  /** @format int32 */
  totalRecords?: number;
  errors?: ImportError[] | null;
  sample?: PersonCreateModel[] | null;
}

export interface PersonCustomerViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  /** @format uuid */
  customerId?: string;
  salutation?: CustomerSalutation;
  surname?: string | null;
  givenNames?: string | null;
  nationality?: string | null;
  fullName?: string | null;
  gender?: CustomerGender;
  civilStatus?: CustomerCivilStatus;
  /** @format date */
  dateOfBirth?: string | null;
  profession?: string | null;
}

export interface PhoneNumberVerificationCreateModel {
  referenceId?: string | null;
  /** @format uuid */
  bulkId?: string | null;
  /** Determine if we should execute this call in the background */
  isBlocking?: boolean;
  /**
   * The customer for who this request is being made against
   * @format uuid
   */
  customerId?: string | null;
  /**
   * Customer's Phone number
   * @minLength 1
   */
  phoneNumber: string;
}

export interface PhoneNumberVerificationCreateModelBulkVerificationPreviewResponse {
  /** @format uuid */
  docId?: string;
  document?: DocViewModel;
  /** @format int32 */
  totalRecords?: number;
  errors?: ImportError[] | null;
  sample?: PhoneNumberVerificationCreateModel[] | null;
}

export enum PhoneNumberVerificationStatus {
  Pending = "Pending",
  Success = "Success",
  Failed = "Failed",
  NotFound = "NotFound",
  Barred = "Barred",
}

export interface PhoneNumberVerificationViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  /** @format uuid */
  partnerId?: string;
  partner?: ComboModel;
  /** @format date-time */
  sentAt?: string | null;
  /** @format uuid */
  productId?: string;
  /** @format uuid */
  bulkId?: string | null;
  /** @format uuid */
  customerId?: string | null;
  status?: RequestStatus;
  /** @format date-time */
  startedAt?: string | null;
  /** @format int64 */
  runningBalance?: number;
  /** @format date-time */
  processedAt?: string | null;
  /** @format int32 */
  attemptCount?: number;
  channel?: string | null;
  requestedBy?: string | null;
  requestedByData?: ComboModel;
  referenceId?: string | null;
  errorCode?: string | null;
  errorMessage?: string | null;
  phoneNumber?: string | null;
  registeredName?: string | null;
  network?: string | null;
  verificationStatus?: PhoneNumberVerificationStatus;
}

export interface PhoneNumberVerificationViewModelPaginatedListViewModel {
  /** @format int32 */
  page?: number;
  /** @format int32 */
  totalPages?: number;
  /** @format int32 */
  total?: number;
  data?: PhoneNumberVerificationViewModel[] | null;
  hasPreviousPage?: boolean;
  hasNextPage?: boolean;
}

export enum ProductCategory {
  IdVerification = "IdVerification",
  LandVerification = "LandVerification",
  TinVerification = "TinVerification",
  PhoneVerification = "PhoneVerification",
  DataConsent = "DataConsent",
  AmlScreening = "AmlScreening",
  BusinessSearch = "BusinessSearch",
  PhoneNumberActivity = "PhoneNumberActivity",
}

export interface ProductCreateModel {
  category?: ProductCategory;
  /** @format uuid */
  partnerId?: string;
}

export interface ProductCreditsIoTecPayTopUpModel {
  /** @format uuid */
  partnerId?: string;
  productCategory?: ProductCategory;
  /** @format double */
  credits?: number;
  /** @format double */
  amountToPay?: number;
  /** @minLength 1 */
  memo: string;
  /**
   * @format tel
   * @minLength 1
   */
  mobileMoneyNumber: string;
}

export interface ProductCreditsManualTopUpModel {
  /** @format uuid */
  partnerId?: string;
  productCategory: ProductCategory;
  /**
   * @format double
   * @min 1
   */
  credits?: number;
  /** @minLength 1 */
  memo: string;
}

export interface ProductCreditsWalletToWalletTopUpModel {
  /** @format uuid */
  partnerId?: string;
  productCategory?: ProductCategory;
  /** @format double */
  credits?: number;
  /** @format double */
  amountToPay?: number;
  /** @minLength 1 */
  memo: string;
  /** @minLength 1 */
  walletCode: string;
  walletPin: string | null;
}

export enum ProductPaymentMethod {
  BankDeposit = "BankDeposit",
  MobileMoney = "MobileMoney",
  Free = "Free",
  IoTecWallet = "IoTecWallet",
}

export interface ProductPriceBand {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  lastUpdated?: string | null;
  isDeleted?: boolean;
  category?: ProductCategory;
  /** @maxLength 35 */
  name?: string | null;
  /** @format int32 */
  lowerLimit?: number;
  /** @format int32 */
  upperLimit?: number | null;
  /** @format double */
  unitPrice?: number;
  /** @maxLength 3 */
  currency?: string | null;
  /** @maxLength 50 */
  createdBy?: string | null;
}

export interface ProductPriceBandCreateModel {
  category?: ProductCategory;
  /** @maxLength 35 */
  name?: string | null;
  /** @format int32 */
  lowerLimit?: number;
  /** @format int32 */
  upperLimit?: number | null;
  /** @format double */
  unitPrice?: number;
}

export interface ProductPriceBandUpdateModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  /** @maxLength 35 */
  name?: string | null;
  /** @format int32 */
  lowerLimit?: number;
  /** @format int32 */
  upperLimit?: number | null;
  /** @format double */
  unitPrice?: number;
}

export interface ProductPriceBandViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  name?: string | null;
  /** @format int32 */
  lowerLimit?: number;
  /** @format int32 */
  upperLimit?: number | null;
  /** @format double */
  unitPrice?: number;
  /** @format double */
  purchasableCredits?: number;
  /** @format double */
  purchasableAmount?: number;
  category?: ProductCategory;
  currency?: string | null;
}

export enum ProductPurchaseOrderInvoiceStatus {
  Pending = "Pending",
  Paid = "Paid",
  Cancelled = "Cancelled",
}

export interface ProductPurchaseOrderInvoiceViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  /** @format uuid */
  partnerId?: string;
  productCategory?: ProductCategory;
  /** @format uuid */
  purchaseOrderId?: string;
  invoiceNumber: string | null;
  status?: ProductPurchaseOrderInvoiceStatus;
  /** @format date-time */
  dueDate?: string | null;
  memo: string | null;
  partnerAddress?: string | null;
  partnerEmail: string | null;
  partnerPhone: string | null;
  partnerName: string | null;
  /** @format double */
  amount?: number;
  /** @format double */
  taxAmount?: number;
  /** @format double */
  credits?: number;
  /** @format double */
  unitPrice?: number;
  /** @format uuid */
  updatedBy?: string | null;
}

export interface ProductPurchaseOrderIoTecPayViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  category: string | null;
  currency: string | null;
  walletId: string | null;
  externalId: string | null;
  /** @format uuid */
  partnerId?: string;
  partner?: ComboModel;
  payer: string | null;
  payerNote: string | null;
  /** @format double */
  amount?: number;
  payeeNote: string | null;
  channel?: string | null;
  transactionChargesCategory: string | null;
  productCategory?: ProductCategory;
  status?: IoTecPayCollectionStatus;
  /** @format double */
  credits?: number;
  /** @format double */
  totalAmountPaid?: number;
  /** @format double */
  transactionCharge?: number;
  /** @format double */
  vendorCharge?: number;
  /** @format double */
  totalTransactionCharge?: number;
  payerName?: string | null;
  /** @format uuid */
  purchaseOrderId?: string;
}

export interface ProductPurchaseOrderPreviewRequestModel {
  /** @format uuid */
  partnerId?: string;
  productCategory?: ProductCategory;
  /** @format double */
  credits?: number;
  paymentMethod?: ProductPaymentMethod;
  /** Added for mobile money payment method. */
  mobileMoneyNumber?: string | null;
  /** Added for iotec wallet payment method */
  walletCode?: string | null;
  /** Added for iotec wallet payment method */
  walletPin?: string | null;
}

export interface ProductPurchaseOrderPreviewResponseModel {
  /** @format uuid */
  partnerId?: string;
  productCategory?: ProductCategory;
  /** @format double */
  credits?: number;
  paymentMethod?: ProductPaymentMethod;
  /** @format double */
  amount?: number;
  /** @format double */
  transactionCharges?: number;
  /** Added for mobile money payment method. */
  mobileMoneyNumber?: string | null;
  /** Added for wallet code payment method. */
  walletCode?: string | null;
  /** Added for wallet code payment method. */
  walletPin?: string | null;
}

export enum ProductPurchaseOrderStatus {
  Pending = "Pending",
  Paid = "Paid",
  Cancelled = "Cancelled",
}

export interface ProductPurchaseOrderViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  productCategory?: ProductCategory;
  paymentMethod?: ProductPaymentMethod;
  /** @format uuid */
  partnerId?: string;
  partner?: ComboModel;
  /** @format uuid */
  requestId?: string;
  memo: string | null;
  /** @format double */
  amount?: number;
  /** @format double */
  credits?: number;
  /** @format uuid */
  purchaseOrderDetailId?: string;
  invoiceDetail?: ProductPurchaseOrderInvoiceViewModel;
  ioTecPayDetail?: ProductPurchaseOrderIoTecPayViewModel;
  /** @format uuid */
  priceBandId?: string;
  priceBand?: ProductPriceBand;
}

export interface ProductPurchaseOrderViewModelPaginatedListViewModel {
  /** @format int32 */
  page?: number;
  /** @format int32 */
  totalPages?: number;
  /** @format int32 */
  total?: number;
  data?: ProductPurchaseOrderViewModel[] | null;
  hasPreviousPage?: boolean;
  hasNextPage?: boolean;
}

export interface ProductSubscriptionCreateModel {
  category?: ProductCategory;
  /** @format uuid */
  partnerId?: string;
}

export interface ProductSubscriptionViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  category?: ProductCategory;
  /** @format double */
  creditsBalance?: number;
  /** @format uuid */
  partnerId?: string;
  partner?: ComboModel;
  /** @format uuid */
  priceBandId?: string;
  priceBand?: ComboModel;
}

export interface ProductTopUpInvoiceCreateModel {
  /** @format uuid */
  partnerId?: string;
  productCategory?: ProductCategory;
  /** @format uuid */
  requestId?: string;
  /** @format date-time */
  dueDate?: string;
  memo: string | null;
  /** @format double */
  amount?: number;
  /** @format double */
  taxAmount?: number;
  /** @format double */
  credits: number;
}

export interface ProductTopUpInvoicePayModel {
  /** @format uuid */
  invoiceId?: string;
  /** @format uuid */
  partnerId?: string;
  memo: string | null;
}

export interface ProductTopUpModel {
  /** @format uuid */
  id?: string;
  /**
   * @format int64
   * @min 1
   */
  credits?: number;
  /** @minLength 1 */
  reference: string;
}

export interface ProductViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  category?: ProductCategory;
  /** @format uuid */
  partnerId?: string;
  /** @format int64 */
  balance?: number;
  /** @format date-time */
  lastUpdated?: string | null;
  description?: string | null;
}

export enum RequestStatus {
  Pending = "Pending",
  Processing = "Processing",
  Completed = "Completed",
  Failed = "Failed",
}

export interface Shareholder {
  currency?: string | null;
  givenName?: string | null;
  legalName?: string | null;
  middleName?: string | null;
  numberOfShares?: string | null;
  shareholding?: string | null;
  surname?: string | null;
  type?: string | null;
}

export interface Shares {
  numberOfOrdinaryShares?: string | null;
  numberOfPreferentialShares?: string | null;
  numberOfShares?: string | null;
  numberOfUnallotedShares?: string | null;
  shareCapital?: string | null;
  sharePrice?: string | null;
}

export interface SystemRole {
  id?: string | null;
  name?: string | null;
  description?: string | null;
  requiresPrimaryUser?: boolean;
}

export interface TemplateDocCreateModel {
  /** @format uuid */
  templateId?: string;
  /** @format uuid */
  documentId?: string;
}

export interface TinNumberVerificationRequestModel {
  referenceId?: string | null;
  /** @format uuid */
  bulkId?: string | null;
  /** Determine if we should execute this call in the background */
  isBlocking?: boolean;
  /**
   * The customer for who this request is being made against
   * @format uuid
   */
  customerId?: string | null;
  /**
   * Customer's TIN number
   * @minLength 1
   */
  tin: string;
}

export interface TinNumberVerificationViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  /** @format uuid */
  partnerId?: string;
  partner?: ComboModel;
  /** @format date-time */
  sentAt?: string | null;
  /** @format uuid */
  productId?: string;
  /** @format uuid */
  bulkId?: string | null;
  /** @format uuid */
  customerId?: string | null;
  status?: RequestStatus;
  /** @format date-time */
  startedAt?: string | null;
  /** @format int64 */
  runningBalance?: number;
  /** @format date-time */
  processedAt?: string | null;
  /** @format int32 */
  attemptCount?: number;
  channel?: string | null;
  requestedBy?: string | null;
  requestedByData?: ComboModel;
  referenceId?: string | null;
  errorCode?: string | null;
  errorMessage?: string | null;
  tinNumber?: string | null;
  taxPayerName?: string | null;
  registrationStatus?: string | null;
  verificationStatus?: string | null;
  responsePayload?: UraGetClientRegistrationResult;
}

export interface TinNumberVerificationViewModelPaginatedListViewModel {
  /** @format int32 */
  page?: number;
  /** @format int32 */
  totalPages?: number;
  /** @format int32 */
  total?: number;
  data?: TinNumberVerificationViewModel[] | null;
  hasPreviousPage?: boolean;
  hasNextPage?: boolean;
}

export interface UnauthorizedResult {
  /** @format int32 */
  statusCode?: number;
}

export interface UraGetClientRegistrationResult {
  contactNumber?: string | null;
  county?: string | null;
  district?: string | null;
  errorCode?: string | null;
  errorDesc?: string | null;
  isCustomsAgent?: string | null;
  isLicenseValid?: string | null;
  licenseNumber?: string | null;
  mobileNumber?: string | null;
  postalAddress?: string | null;
  registrationStatus?: string | null;
  subCounty?: string | null;
  tin?: string | null;
  taxPayerEmail?: string | null;
  taxPayerName?: string | null;
  telephoneNumber?: string | null;
  typeofUser?: string | null;
  village?: string | null;
}

export interface UrsbEntityFullResponseData {
  address?: Address;
  businessRegistrationNumber?: string | null;
  directors?: Director[] | null;
  dissolutionDate?: string | null;
  entityName?: string | null;
  incorporatedIn?: string | null;
  incorporationDate?: string | null;
  natureOfBusiness?: string | null;
  /** @format int64 */
  numberOfDirectors?: number | null;
  /** @format int64 */
  numberOfShareholders?: number | null;
  /** @format int64 */
  numberOfSubscribers?: number | null;
  officialEmail?: string | null;
  officialTelephoneNumber?: string | null;
  registrationDate?: string | null;
  registrationStatus?: string | null;
  secretary?: Director[] | null;
  shareCapital?: string | null;
  shareholders?: Shareholder[] | null;
  shares?: Shares;
  subType?: string | null;
  subscribers?: Director[] | null;
  type?: string | null;
}

export interface UserViewModel {
  subject?: string | null;
  /** @format uuid */
  contactId?: string | null;
  roles?: string[] | null;
  name?: string | null;
  email?: string | null;
  clientId?: string | null;
  authMode?: AuthMode;
  /** @format uuid */
  partnerId?: string;
  authority?: string | null;
  identityProvider?: string | null;
  /** @format date-time */
  loginTime?: string | null;
  emailVerified?: boolean;
  isClientCredentials?: boolean;
}

export interface VerifyPersonInformationRequestModel {
  referenceId?: string | null;
  /** @format uuid */
  bulkId?: string | null;
  /** Determine if we should execute this call in the background */
  isBlocking?: boolean;
  /**
   * The customer for who this request is being made against
   * @format uuid
   */
  customerId?: string | null;
  nationalId?: string | null;
  documentId?: string | null;
  dateOfBirth?: string | null;
  surname?: string | null;
  givenNames?: string | null;
}

export interface VerifyPersonInformationViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  /** @format uuid */
  partnerId?: string;
  partner?: ComboModel;
  /** @format date-time */
  sentAt?: string | null;
  /** @format uuid */
  productId?: string;
  /** @format uuid */
  bulkId?: string | null;
  /** @format uuid */
  customerId?: string | null;
  status?: RequestStatus;
  /** @format date-time */
  startedAt?: string | null;
  /** @format int64 */
  runningBalance?: number;
  /** @format date-time */
  processedAt?: string | null;
  /** @format int32 */
  attemptCount?: number;
  channel?: string | null;
  requestedBy?: string | null;
  requestedByData?: ComboModel;
  referenceId?: string | null;
  errorCode?: string | null;
  errorMessage?: string | null;
  nationalId?: string | null;
  documentId?: string | null;
  dateOfBirth?: string | null;
  surname?: string | null;
  givenNames?: string | null;
  otherNames?: string | null;
  /** @format uuid */
  multiRequestId?: string;
  /** @format int32 */
  permutation?: number;
  matchingStatus?: string | null;
  cardStatus?: string | null;
  transactionStatus?: string | null;
}

export interface VerifyPersonInformationViewModelPaginatedListViewModel {
  /** @format int32 */
  page?: number;
  /** @format int32 */
  totalPages?: number;
  /** @format int32 */
  total?: number;
  data?: VerifyPersonInformationViewModel[] | null;
  hasPreviousPage?: boolean;
  hasNextPage?: boolean;
}

export interface XContactModel {
  id?: string | null;
  name?: string | null;
  email?: string | null;
  phone?: string | null;
}
