import React, { useState } from 'react';

import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/FileCopyOutlined';

interface IProps {
  value?: string
}

const CopyWrapper = ({ value }: IProps) => {
  const [show, setShow] = useState(false);
  if (!value) return <span>&nbsp;</span>;

  function handleMouseOver() {
    setShow(true);
  }

  function handleMouseOut() {
    setShow(false);
  }

  return (
        <span
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            style={{ width:'100%' }}
        >
            <label style={{ textOverflow:'ellipsis' }}>{value}</label>
            &nbsp;
            <IconButton
                style={{ visibility: show ? 'visible' : 'hidden' }}
                color="default"
                aria-label="Copy value"
                title="Copy value"
                component="span"
                size="small"
                onClick={async () => {
                  try {
                    await navigator.clipboard.writeText(value);
                  } catch (e) {
                    console.error(e);
                  }
                }}
            >
        <ContentCopyIcon fontSize="small"/>
      </IconButton>
    </span>
  );
};

export default CopyWrapper;
