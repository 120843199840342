import type { FC } from 'react';
import { appSettings } from '../../config';
import { ProfileLayout } from './profile/ProfileLayout';
import Loading from '../../components/loaders/Loading';

const SlashScreen: FC<React.PropsWithChildren<unknown>> = () => {
  const title = `Welcome to ${appSettings.appName}`;
  return (
    <ProfileLayout title={title}>
      <Loading/>
    </ProfileLayout>
  );
};

export default SlashScreen;
