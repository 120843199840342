import { isEmpty, isNumber } from 'lodash';
import { isDate } from 'date-fns';
import { Option } from './option';
import { enumToArray } from '../../utils/stringHelpers';

export const hasNoValue = (text: any) => {
  if (text === 0) return false;
  if (isDate(text)) return false;
  if (isNumber(text)) return false;
  return isEmpty(text);
};

export const hasValue = (text: any) => !hasNoValue(text);

export const toOptions = (data: string[]): Option[] => data.map((it) => ({ name: it, id: it }));

export const comboParser = ({ id, name }: any):Option => ({ id, name });

export function enumToOptions(typeData: any) {
  return toOptions(enumToArray(typeData));
}
