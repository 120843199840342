import * as React from 'react';
import { useField } from 'formik';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { hasValue } from './inputHelpers';

interface IProps {
  name: string
}

const XTextAreaInput = ({ name, margin = 'dense', rows = 4, ...props }: TextFieldProps & IProps) => {
  const [field, meta] = useField({ name });
  const error = hasValue(meta.error) ? meta.error : undefined;
  const showError = Boolean(error && meta.touched);
  return (
    <TextField
      {...field}
      {...props}
      margin={margin}
      fullWidth
      error={showError}
      helperText={showError && error}
      value={field.value || ''}
      multiline
      rows={rows}
      autoComplete="off"
    />
  );
};

export default XTextAreaInput;
