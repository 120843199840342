import * as React from 'react';
import { Field, FieldProps, getIn, } from 'formik';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { hasValue } from './inputHelpers';
import { Option } from './option';

interface IProps {
  label: string
  name: string
  options: Option[]
  margin?: 'none' | 'dense' | 'normal'
}

const XRadioInput = (props: IProps) => {
  const { label = '', options = [], margin = 'dense', ...rest } = props;
  const render = (fieldProps: FieldProps) => {
    const { field, form } = fieldProps;
    const { name } = field;
    const { value } = field;
    const error = getIn(form.errors, name);
    const isTouched = getIn(form.touched, name);
    const wasSubmitted = form.submitCount > 0;
    const showError = hasValue(error) && (isTouched || wasSubmitted);

    function handleBlur() {
      form.setFieldTouched(name);
    }

    return (
      <FormControl
        error={showError}
        margin={margin}
      >
        <FormLabel htmlFor={name}>{label}</FormLabel>
        <RadioGroup
          {...field}
          onBlur={handleBlur}
          value={value || ''}
          row
        >
          {
                    options.map(
                      (it) => (
                        <FormControlLabel
                          key={it.id}
                          value={it.id}
                          label={it.name}
                          control={<Radio color="primary" />}
                        />
                      )
                    )
                }
        </RadioGroup>
        {
                showError && <FormHelperText>{error}</FormHelperText>
            }
      </FormControl>
    );
  };
  return (
    <Field {...rest}>
      {render}
    </Field>
  );
};
export default XRadioInput;
