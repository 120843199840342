import React, { ChangeEvent } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { TextFieldProps } from '@mui/material/TextField/TextField';
import { AutocompleteProps } from '@mui/material';
import { Option } from '../inputs/option';
import { useTheme } from '@mui/material/styles';

export type ComboValue = string | Option | (string | Option)[] | null;

interface IProps {
  options: (string | Option)[]
  label: string
  value: ComboValue
  showError?: boolean
  helperText?: string
  name: string
  variant?: 'outlined' | 'filled' | 'standard'
  multiple?: any;
  size?: 'medium' | 'small'
  onChange: (value: ComboValue) => void
  onBlur?: () => void
  textFieldProps?: TextFieldProps
  margin?: 'none' | 'dense' | 'normal'
}

type OptionalBool = boolean | undefined;
type BaseProps = AutocompleteProps<string | Option, OptionalBool, OptionalBool, OptionalBool>;
type AutoProps = Omit<BaseProps, 'variant' | 'multiple' | 'renderInput' | 'onChange' | 'value'>;
export type PComboProps = IProps & AutoProps;
const PComboInput = (props: PComboProps) => {
  const theme = useTheme();

  const {
    onChange, onBlur,
    options, value, label,
    variant,
    multiple,
    margin = 'dense',
    helperText, showError, textFieldProps,
    ...extraProps
  } = props;

  function handleChange(
    event: ChangeEvent<{}>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    comboValue: ComboValue, _: any
  ) {
    onChange(comboValue);
  }

  function getOptionLabel(o: string | Option) {
    if (typeof o === 'string') {
      return o;
    }
    if (typeof o === 'object') {
      const obj = o as Option;
      return obj?.name;
    }
    return '';
  }

  return (
    <Autocomplete
      {...extraProps}
      componentsProps={{
        paper: {
          style: {
            boxShadow: theme.shadows[10],
            borderRadius: theme.shape.borderRadius,
          }
        }
      }}
      options={options}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => (
        <TextField
          {...params}
          {...textFieldProps}
          label={label}
          variant={variant}
          fullWidth
          error={showError}
          helperText={showError && helperText}
          onBlur={onBlur}
          margin={margin}
          autoComplete="lava"
        />
      )}
      onChange={handleChange}
      onBlur={onBlur}
      multiple={multiple}
      value={value || (multiple ? [] : null)}
      clearOnEscape
      filterSelectedOptions
    />
  );
};

export default PComboInput;
