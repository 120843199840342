import * as yup from 'yup';
import dayjs from 'dayjs';
import { dateFormats } from '../inputs/XDateInput';

export const invalidInputs: any[] = [null, 'null', 'undefined', undefined, ''];
export const reqMsg = 'This field is required';
export const reqMsgInvalid = 'Invalid input';

export const reqNumberYear = yup.number().min(1400, reqMsgInvalid).max(5099, reqMsgInvalid).required(reqMsg)
  .nonNullable();

export const reqString = yup.string().required(reqMsg).min(1).notOneOf(invalidInputs, reqMsg);
export const reqPasswordMatch = yup.string().required(reqMsg)
  .oneOf([yup.ref('password')], 'Passwords must match');

export const reqUrl = yup.string().required(reqMsg).min(1).notOneOf(invalidInputs, reqMsg).url('Invalid URL');

export const reqPinMatch = yup.string().required(reqMsg)
  .oneOf([yup.ref('pin')], 'PINS must match');

export const reqEmail = yup.string().email('Must be a valid email').required(reqMsg).notOneOf(invalidInputs, reqMsg);
export const validateEmail = yup.string().email('Must be a valid email').nullable();

export function parseDateString(value, originalValue) {
  return dayjs(originalValue, dateFormats).toDate();
}

export const reqDate = yup.date()
  .transform(parseDateString)
  .required(reqMsg).notOneOf(invalidInputs, reqMsg);

export const reqBirthday = yup.date()
  .transform(parseDateString)
  .max(dayjs(), 'Cannot be in the future')
  .required('Required');

export const reqDateAbove18 = yup.date()
  .transform(parseDateString)
  .max(dayjs().subtract(18, 'year'), 'Must be above 18 years')
  .required('Required');

export const validateDateAbove18 = yup.date().nullable()
  .transform(parseDateString)
  .max(dayjs().subtract(18, 'year'), 'Must be above 18 years');

export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const reqPhone = yup.string().matches(phoneRegExp, 'Phone number is not valid').required(reqMsg).notOneOf(invalidInputs, reqMsg);
export const validatePhone = yup.string().matches(phoneRegExp, 'Phone number is not valid').nullable();

export const reqObject = yup
  .object()
  .required(reqMsg)
  .notOneOf(invalidInputs, reqMsg);

export const reqArray = yup
  .array()
  .ensure()
  .required(reqMsg)
  .min(1);


export const reqPositiveInteger = yup
  .number()
  .required(reqMsg)
  .positive()
  .integer();


export const reqNumber = yup
  .number()
  .notOneOf([null, 0], reqMsg)
  .required(reqMsg);

export const tagsSchema = yup
  .string()
  .matches(/^[a-zA-Z0-9,]*$/, 'Tags should be comma separated, no spaces');

export const req9DigitString = yup.string()
  .matches(/^([0-9]{9})$/, 'Card number should have 9 digits')
  .required(reqMsg)
  .notOneOf(invalidInputs, reqMsg);

export const req14CharAlphaNumericString = yup.string()
  .matches(/^([A-Za-z0-9]{14})$/, 'NIN should have 14 characters in caps')
  .required(reqMsg)
  .notOneOf(invalidInputs, reqMsg);

export const positiveNumberIncludingZero = yup
  .number()
  .positive().nullable();

export const reqPositiveNumberIncludingZero = yup
  .number()
  .min(0, 'Must be a positive number or zero')
  .required(reqMsg);
